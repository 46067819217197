import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ColorSchemesExample.css';

function ColorSchemesExample() {
  const navigate = useNavigate(); // For programmatic navigation
  const location = useLocation(); // Get the current route
  const [activeLink, setActiveLink] = useState(location.pathname); // Track active route
  const [expanded, setExpanded] = useState(false); // Track if navbar is expanded

  const handleNavigation = (path) => {
    navigate(path); // Navigate programmatically
    setActiveLink(path); // Update active link state
  };

  return (
    <div className="navbar-overlay">
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        className={`navbar-transparent ${expanded ? 'expanded' : ''}`}
        onToggle={() => setExpanded(!expanded)} // Toggle expansion
        expanded={expanded}
      >
        <Container>
          {/* Logo and Brand */}
          <Navbar.Brand className="d-flex align-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              width="40"
              height="40"
              className="d-inline-block align-top mr-3"
              alt="Logo"
            />
            <span className="h5 mb-0 ml-2">The Libyan Engineering Consulting Co.</span>
          </Navbar.Brand>

          {/* Collapsible Navbar (For mobile) */}
          <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="navbar-nav">
            {/* Mobile Navbar links (appear when expanded) */}
            <div className={`mobile-nav-links ${expanded ? 'show' : ''}`}>
              <Nav className="ml-auto">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
                  onClick={() => handleNavigation('/')}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about"
                  className={`nav-link ${activeLink === '/about' ? 'active' : ''}`}
                  onClick={() => handleNavigation('/about')}
                >
                  About
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/journal"
                  className={`nav-link ${activeLink === '/journal' ? 'active' : ''}`}
                  onClick={() => handleNavigation('/journal')}
                >
                  Journal
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contacts"
                  className={`nav-link ${activeLink === '/contacts' ? 'active' : ''}`}
                  onClick={() => handleNavigation('/contacts')}
                >
                  Contacts
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>

          {/* Desktop Navbar links (Always visible) */}
          <div className="desktop-nav-links d-none d-lg-flex">
            <Nav className="ml-auto">
              <Nav.Link
                as={Link}
                to="/"
                className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
                onClick={() => handleNavigation('/')}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className={`nav-link ${activeLink === '/about' ? 'active' : ''}`}
                onClick={() => handleNavigation('/about')}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/journal"
                className={`nav-link ${activeLink === '/journal' ? 'active' : ''}`}
                onClick={() => handleNavigation('/journal')}
              >
                Journal
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contacts"
                className={`nav-link ${activeLink === '/contacts' ? 'active' : ''}`}
                onClick={() => handleNavigation('/contacts')}
              >
                Contacts
              </Nav.Link>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

export default ColorSchemesExample;
