import React, { useState, useEffect } from 'react';
import './About.css';
import Section5 from './Section5';
import Footer from './Footer';

const About = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const carouselImages = [
    '/SecondaryCarousel_1.png',
    '/SecondaryCarousel_2.png',
    '/SecondaryCarousel_3.png',
    '/SecondaryCarousel_4.png',
    '/SecondaryCarousel_5.png',
    '/SecondaryCarousel_6.png',
    '/SecondaryCarousel_7.png',
  ]; // Replace with your image paths

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % carouselImages.length);
    }, 10000); // Change image every 10 seconds
    return () => clearInterval(interval);
  }, [carouselImages]);

  const teamMembers = [
    { photo: '/TeamMember1.png', name: 'Arch. Sami Jaouda ', title: 'CEO' },
    { photo: '/TeamMember2.png', name: 'Eng. Mohammed El Mheishi ', title: 'Co-Founder' },
    { photo: '/TeamMember3.png', name: 'Arch. Salah  El Sheikhi ', title: 'General Manager' },
    { photo: '/TeamMember4.png', name: 'Arch. Marwa Benzayed', title: 'Senior Architect' },
    { photo: '/TeamMember5.png', name: 'Arch. Narmin Alturky ', title: 'Architect' },
    { photo: '/TeamMember6.png', name: 'Arch. Nizar Kwafi ', title: 'Senior Architect' },
    { photo: '/TeamMember7.png', name: 'Eng. Suliman Gauda ', title: 'Electrical Engineering' },
    { photo: '/TeamMember8.png', name: 'Arch. Sofian Bendardaf ', title: 'Architect' },
    { photo: '/TeamMember9.png', name: 'Eng. Fatma Elarabi ', title: 'Interior Designer' },
    { photo: '/TeamMember13.png', name: 'End. Esra Mugharbi ', title: 'Interior Designer' },
    { photo: '/TeamMember14.png', name: 'Eng. Abdul Fattah Mohamed ', title: 'Civil Engineer' },
    { photo: '/TeamMember15.png', name: 'Eng. Alamin Elkwafi ', title: 'Electrical Engineer' },
    { photo: '/TeamMember10.png', name: 'Arch. Saed elbarany', title: 'Architect' },
    { photo: '/TeamMember11.png', name: 'Arch. Heba Gumati ', title: 'Architect' },
    { photo: '/TeamMember12.png', name: 'Mr. Gabriel Mheishi ', title: 'Public Relations' },

  ];

  return (
    <div className="about-page">
      {/* Section 1 */}
      <div className="carousel-section">
        <img
          src={carouselImages[currentImage]}
          alt="Carousel"
          className="carousel-image"
        />
      </div>

      {/* Section 2 */}
      <div className="story-section aligned-content">
        <div className="story-left">
          <img src="/AboutSection_First.png" alt="Our Story" />
        </div>
        <div className="story-right">
          <h2>Our Story</h2>
          <p className="firstStoryParagraph">
            Founded in Benghazi in 1959, the Libyan Engineering Consultancy Co. was established by two visionary engineers:
          </p>
          <ul className="bulletpoints">
            <li><b>Arch. Fathi Sulaiman Jaouda</b></li>
            <li><b>Eng. Mohamed Hussein Mheishi</b></li>
          </ul>
          <p className="firstStoryParagraph">
            Years followed, they were joined by:
          </p>
          <ul className="bulletpoints">
            <li><b>Eng. Ali Imneina</b></li>
            <li><b>Arch. Sami Fathi Jaouda</b></li>
          </ul>
          <p className="firstStoryParagraph">
            Together, they embarked on a mission to contribute to Libya’s development through groundbreaking engineering projects. For over six decades we have remained dedicated to enhancing the built environment by delivering innovative and sustainable solutions. Our firm’s commitment to quality and precision continues to drive Libya’s development, with a growing presence in international markets. <br /><br />
            From urban planning and infrastructure to educational and healthcare institutions, our journey is one of growth, excellence, and enduring impact. Today, we stand as a beacon of engineering expertise, not only in Libya but on a global stage, offering a wide range of services across architectural, civil, and constructional engineering, as well as surveying and project management.
          </p>
          <h2>Mission Statement</h2>
          <p>
            At the heart of our mission is a steadfast commitment to leveraging our expertise to drive positive change. Every project we undertake is a step towards realizing our mission of enhancing the human experience through quality-driven, innovative engineering solutions.
          </p>
          <h2>Vision Statement</h2>
          <p>
            Our vision serves as the guiding star for our team as we continue to push boundaries in engineering innovation. We strive to be at the forefront of the industry, known for our ability to address complex challenges with forward-thinking solutions that enrich communities and improve lives.
          </p>
        </div>
      </div>

      {/* Section 3 */}
      <div className="team-section aligned-content">
        <img
          src="/AboutSection_GroupCompanyPhoto.png"
          alt="Team Banner"
          className="team-banner"
        />
        <h2>Our Team</h2>
        <p>Our multidisciplinary team brings together architects, planners, consultants, and project managers with diverse expertise and a shared commitment to creating exceptional spaces</p>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img
                src={member.photo}
                alt={member.name}
              />
              <h3>{member.name}</h3>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
        <div>
        </div>

  {/* Mobile Stacked */}

    </div>
    <div className="team-stacked-container">
    <h2>Our Team</h2>
    <p>Meet the brilliant minds behind our projects!</p>
    {teamMembers.map((member, index) => (
      <div key={index} className="team-stacked-member">
        <img src={member.photo} alt={member.name} />
        <div className="team-stacked-info">
          <h3>{member.name}</h3>
          <p>{member.title}</p>
        </div>
      </div>
    ))}
        <div className="team-footer">
          
        </div>
        <div className="services-container">
      <div className="services-left">
        <h2>Services</h2>
        <p>Comprehensive services designed to bring your vision to life with innovation and care.</p>
      </div>
      <div className="services-right">
        <div className="service">
          <h4>Architecture</h4>
          <p>Designing innovative and sustainable buildings that stand the test of time.</p>
        </div>
        <div className="service">
          <h4>Consultancy</h4>
          <p>Providing expert advice to navigate complex challenges and achieve success.</p>
        </div>
        <div className="service">
          <h4>Urban Planning</h4>
          <p>Creating well-planned, sustainable communities for the future.</p>
        </div>
        <div className="service">
          <h4>Project Management</h4>
          <p>Ensuring smooth project execution with precision and efficiency.</p>
        </div>
      </div>
    </div>
    <div className="stats-container">
      <div className="stat">
        <h3>+200 Projects</h3>
        <p>Completed successfully with attention to detail and client satisfaction.</p>
      </div>
      <div className="stat">
        <h3>+50 Collaborations</h3>
        <p>With industry leaders, creating synergies for greater impact.</p>
      </div>
      <div className="stat">
        <h3>+65 Years</h3>
        <p>Of experience in delivering high-quality projects across multiple sectors.</p>
      </div>
    </div>
      </div>
      <section class="associates-section">
  <div class="associates-content">

    <div class="associates-left">
      <h2>Associates</h2>
      <p>
        Our esteemed associates have helped shape the architectural landscape worldwide. 
        Collaborating across cities and cultures, we bring innovation to life. 
        Discover our trusted partners who share our vision for excellence.
      </p>
    </div>

 
    <div class="associates-right">
      <ul class="partners-list">
        <li>Foster and Partners, London</li>
        <li>Howard Humphreys, London</li>
        <li>James Cubitt, London</li>
        <li>Praxis, Amman</li>
        <li>Valerio Moscow Architects, Rome</li>
    
        <li>Jamat Al Emara - Cairo </li>
        <li>Al Bonian - Cairo</li>
        <li>Integrated Design Group - Cairo</li>
        <li>Al Emara Consulting Office - Benghazi</li>
        <li>Al Labina - Tripoli</li>
        <li>Architecture and Urbanism Studio - London</li>
        <li>AL Mada Architects - Musrata</li>
        <li>Arkan for Investment Management - Tripoli</li>
        <li>Lacroze-Miguens-Prati Arquitectos - New York</li>
        <li>Edward Cullinan Architects - London </li>
        <li>Ramboll - London</li>
        <li>Hyland Edgar Driver - London</li>
        <li>Element Energy - London</li>
        <li>Space Syntax - London</li>
      </ul>
      
        <img class="partner-photo" src="/Associates_Team_Picture.png" alt="Partner Photo" />
      
    </div>
  </div>
</section>
  <Footer></Footer>
    </div>
  );
};

export default About;
