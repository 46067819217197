import React, { useState, useEffect } from 'react';
import './Section2.css';

const images = [
  { 
    src: '/Section2_Image1_Figma.jpg', 
    title: 'Building Legacies', 
    body: 'With over six decades of excellence, we have been a cornerstone in shaping modern Libya, combining innovation with a deep respect for tradition.', 
  },
  { 
    src: '/Section2_Image2_Figma.jpg', 
    title: 'A Vision for Tomorrow', 
    body: 'Guided by a forward-thinking vision, we continue to redefine the engineering landscape, pioneering projects that stand the test of time.', 
  },
  { 
    src: '/Section2_Image3_Figma.jpg', 
    title: 'Architecting the Future', 
    body: 'Our architectural expertise merges creativity with functionality, designing iconic structures that define Libya’s skyline and enrich its cultural heritage.', 
  },
];

const Section2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const currentImage = images[currentIndex];
  const handleDownload = () => {
    window.open('https://drive.google.com/file/d/1uw-SnQdDkECQev_Rewr3ax-sJdDD9aj9/view?usp=sharing', '_blank');
  };

  return (
    <div className="section2">
      <div className="section2-header">
        <h2>Explore Our Site</h2>
        <div className="section2-arrow">↓</div>
      </div>
      <div className="section2-main">
        <div className="section2-text-box-container">
          <div className="section2-text-box">
            <h3>{currentImage.title}</h3>
            <p>{currentImage.body}</p>
          </div>
          <div className="section2-below-box">
            <div className="section2-text">
              <p>You can learn more about our company’s <br /> history and our story from here</p>
            </div>
            <button className="section2-learn-more" onClick={handleDownload}>Learn More</button>
          </div>
        </div>
        <div className="section2-carousel">
          <img src={currentImage.src} alt={currentImage.title} className="section2-carousel-image" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
