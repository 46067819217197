import React, { useState } from 'react';
import './Section4.css';

const galleryImages = [
  {
    src: '/section4_Lathroon.png',
    place: 'Lathroun Resort',
    description: 'Set in the picturesque coastal town of Lathroun, Libya, the Lathroun Resort is a sanctuary of tranquility and luxury, the resort is designed with a focus on seclusion and scenic beauty.',
  },
  {
    src: '/alahly_mobile_section4.png',
    place: 'Al Ahly Mall',
    description: 'Nestled in the vibrant city of Benghazi, the Al Ahly Mall and Hotel project stands as a beacon of Modern tranquility. Covering an expansive area of 40,000 sqm.',
  },
  {
    src: '/section4_Endair.png',
    place: 'Endair Towers',
    description: 'Overlooking the Mediterranean Sea this trio of towers comprises a luxurious hotel, a bustling mall and a state-of-the-art office building. The integration of these three distinct functions within a single complex.',
  },
  {
    src: '/section4_Ozou.png',
    place: 'Ouzo Hotel ',
    description: 'The Ouzo Hotel, situated in the picturesque location of Benghazi, Libya, overlooking the 23rd of July Lake.',
  },
];

const handleDownload = () => {
  window.open('https://drive.google.com/file/d/1uw-SnQdDkECQev_Rewr3ax-sJdDD9aj9/view?usp=sharing', '_blank');
};
const Section4 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="section4" id="section4">
      {/* Left Hand Side */}
      <div className="section4-left-content">
        <h2 className="section4-section-title">Projects </h2>
        <div className="section4-place-name">{galleryImages[currentIndex].place}</div>
        <p className="section4-description">{galleryImages[currentIndex].description}</p>
        <button className="section4-learn-more-button" onClick={handleDownload }>Learn More</button>
      </div>

      {/* Right Hand Side (Carousel) */}
      <div className="section4-right-content">
        <div className="section4-gallery-container">
          <img
            src={galleryImages[currentIndex].src}
            alt={galleryImages[currentIndex].place}
            className="section4-gallery-image"
          />
        </div>
        {/* Right Arrow Positioned outside the carousel */}
        <span className="carousel-arrow-right" onClick={() => setCurrentIndex((currentIndex + 1) % galleryImages.length)}>&gt;</span>
      </div>
    </div>
  );
};

export default Section4;
