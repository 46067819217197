import React, { useState, useEffect } from 'react';
import SplashScreen from './SplashScreen';
import Section2 from './Section2';
import Section3 from './Section3';
import Gallery from './Gallery';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import ColorSchemesExample from './ColorSchemesExample';
import About from './About';
import './Styles.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; 
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import Journal from './Journal';
import Contacts from './Contacts';

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
      setShowContent(true);
    }, 3000);
  }, []);

  return (
    <Router>
      <div className="App">
        {showSplash ? (
          <SplashScreen />
        ) : (
          <div className={`website-content ${showContent ? 'show-content' : ''}`}>
            <ColorSchemesExample />
            <Routes>
              <Route path="/" element={<>
                <Gallery />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Footer />
                <MobileFooter />
              </>} />
              <Route path="/home" element={<>
                <Gallery />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Footer />
                <MobileFooter />
              </>} />
              <Route path="/about" element={<About />} />
              <Route path="/journal" element={<Journal />} />
              <Route path="/contacts" element={<Contacts />} />
            </Routes>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
