import React from 'react';
import './Section5.css';

const Section5 = () => (
  <div className="section5" id="section5">
    {/* Title with small line */}
    <div className="section-title">
      <span className="expertise-text">Our Expertise</span>
      <div className="line"></div>
    </div>
    
    {/* Services and small descriptions */}
    <div className="services-container">
      <div className="services-left">
        <h2>Services</h2>
        <p>Comprehensive services designed to bring your vision to life with innovation and care.</p>
      </div>
      <div className="services-right">
        <div className="service">
          <h4>Architecture</h4>
          <p>Designing innovative and sustainable buildings that stand the test of time.</p>
        </div>
        <div className="service">
          <h4>Consultancy</h4>
          <p>Providing expert advice to navigate complex challenges and achieve success.</p>
        </div>
        <div className="service">
          <h4>Urban Planning</h4>
          <p>Creating well-planned, sustainable communities for the future.</p>
        </div>
        <div className="service">
          <h4>Project Management</h4>
          <p>Ensuring smooth project execution with precision and efficiency.</p>
        </div>
      </div>
    </div>

    {/* Stats Section */}
    <div className="stats-container">
      <div className="stat">
        <h3>+200 Projects</h3>
        <p>Completed successfully with attention to detail and client satisfaction.</p>
      </div>
      <div className="stat">
        <h3>+50 Collaborations</h3>
        <p>With industry leaders, creating synergies for greater impact.</p>
      </div>
      <div className="stat">
        <h3>+65 Years</h3>
        <p>Of experience in delivering high-quality projects across multiple sectors.</p>
      </div>
    </div>

    {/* Divider Line */}
    <div className="divider-line"></div>

    {/* Get in Touch Section */}
    <div className="contact-section">
      <h2>Get in Touch</h2>
      <p>Visit our Contact Page for detailed information on how to reach out our offices and departments. Whether you’re looking for specific department contacts, office locations, and more.</p>
      <button className="contact-button">Contact</button>
    </div>

    {/* Centered Image */}
    <div className="centered-image">
      <img src="/Basemap-image.png" alt="Contact Image" />
    </div>
  </div>
);

export default Section5;
