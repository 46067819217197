import React, { useState, useEffect } from 'react';
import './Journal.css';
import Footer from './Footer';

const Journal = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const carouselImages = [
    '/SecondaryCarousel_1.png',
    '/SecondaryCarousel_2.png',
    '/SecondaryCarousel_3.png',
    '/SecondaryCarousel_4.png',
    '/SecondaryCarousel_5.png',
    '/SecondaryCarousel_6.png',
    '/SecondaryCarousel_7.png',
  ]; // Replace with your image paths

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % carouselImages.length);
    }, 10000); // Change image every 10 seconds
    return () => clearInterval(interval);
  }, [carouselImages]);

  return (
    <div className="journal">
      {/* Carousel Section */}
      <div className="carousel-container">
        <img
          src={carouselImages[currentImage]}
          alt="Carousel"
          className="carousel-image"
        />
      </div>

      {/* Content Section */}
      <div className="journal-content">
        {/* Left Column: Journal Archive */}
        <div className="left-column">
          <h4 className="archive-title">Journal Archive</h4>
          <div className="archive-item">
            <img src="/JournalThumbnail1.png" alt="News 1" className="archive-thumbnail" />
            <div className="archive-text">
              <p className="archive-news-title">Libya Build 2024</p>
              <p className="archive-news-body">Our participation in Libya's biggest Engineering Event</p>
            </div>
          </div>
          <div className="archive-item">
            <img src="/JournalThumbnail2.png" alt="News 2" className="archive-thumbnail" />
            <div className="archive-text">
              <p className="archive-news-title">65 years Celebration</p>
              <p className="archive-news-body">Our celebration of over six decades of dedicated work</p>
            </div>
          </div>
          <div className="archive-item">
            <img src="/LastJournal.png" alt="News 3" className="archive-thumbnail" />
            <div className="archive-text">
              <p className="archive-news-title">Collaborations</p>
              <p className="archive-news-body">Our collaborations on impactful projects</p>
            </div>
          </div>
        </div>

        {/* Right Column: Featured News */}
        <div className="right-column">
          <h2 className="featured-title">Libya Build 2024</h2>
          <h4 className="featured-subtitle">The Largest Construction Expo in the Region</h4>
          <p className="featured-date">June 15–18, 2024</p>
          <img src="/JournalPhoto1.png" alt="Libya Build" className="featured-photo1" />
          <p className="featured-paragraph">
          From urban planning and infrastructure to educational and healthcare institutions, our journey is one of growth, excellence, and enduring impact. Today, we stands as a beacon of engineering expertise, not only in Libya but on a global stage, offering a wide range of services across architectural, civil and Constructional Engineering, as well as surveying and project management.
          </p>
          <img src="/JournalPhoto2.png" alt="Exhibit 1" className="featured-photo2" />
          <img src="/JournalPhoto3.png" alt="Exhibit 2" className="featured-photo3" />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Journal;
