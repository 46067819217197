import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

import './Fonts/fonts.css'; 



function Gallery() {
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the index of the currently displayed image
  const [additionalTextIndex, setAdditionalTextIndex] = useState(0); // State to track the index of the additional changing text

  // Function to handle slide change
  const handleSlideChange = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };
  const handleAdditionalTextChange = (selectedIndex) => {
    setAdditionalTextIndex(selectedIndex);
  };
  // Function to render text based on the current index
  const renderText = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="text-container">
            <h2>The Roots of Innovation</h2>
            <p>
              Founded in Benghazi in 1959 by two visionary engineers, LEO began as a small consultancy with big ambitions. Over the years, it has grown into a leading engineering office that has played a pivotal role in Libya’s development,
              from urban planning and infrastructure to educational and healthcare facilities.
            </p>
          </div>
        );
      case 1:
        return (
          <div className="text-container">
            <h2>A Legacy of Excellence</h2>
            <p>
              Every project tells a story of challenges met with innovative solutions. Our commitment to quality, sustainability, and 
              precision has left a mark on Libya’s landscape, helping to drive its modernization and growth.
            </p>
          </div>
        );
      case 4:
        return (
          <div className="text-container">
            <h2>Beacons of Heritage</h2>
            <p>
              Constructed in 1968, this architectural marvel is one of Benghazi’s oldest and most revered cultural landmarks. 
              LEO’s engineering expertise brought to life a structure that has not only stood the test of time but also continues to 
              serve as a spiritual center and a beacon of tradition for the community.
            </p>
          </div>
        );
      case 2:
        return (
          <div className="text-container">
            <h2>Designing the Future</h2>
            <p>
              At the forefront of engineering consultancy, LEO is shaping the future by embracing new technologies and methodologies. Our work extends beyond borders, bringing global best practices to local projects.
            </p>
          </div>
        );
      case 5:
        return (
          <div className="text-container">
            <h2>Architectural Visions</h2>
            <p>
              The Roots of Innovation: Founded in Benghazi in 1959 by two visionary engineers, LEO began as a small 
              consultancy with big ambitions. Over the years, it has grown into a leading engineering office that has played a 
               pivotal role in Libya’s development, from urban planning and infrastructure to educational and healthcare facilities.
            </p>
          </div>
        );
      case 3:
        return (
          <div className="text-container">
            <h2>Icons of Resilience</h2>
            <p>
              In the early 1980s, LEO embarked on an ambitious project to create a vast residential development that today stands 
              as a testament to brilliant engineering and foresight. Comprising 7000 housing units, this project not only 
               accommodates thousands but also symbolizes stability and community resilience in Libya’s urban landscape.
            </p>
          </div>
        );
      case 6:
        return (
          <div className="text-container">
            <h2>Commitment to Sustainability</h2>
            <p>
              At LEO, we are deeply committed to the principles of sustainability and environmental stewardship. Each project is approached with the goal of minimizing environmental impact while maximizing efficiency and functionality. Our 
               approach not only ensures the longevity of our constructions but also supports the sustainable development of the 
               communities we serve.
            </p>
          </div>
        );
      default:
        return null;
    }
  };
  const renderAdditionalText = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="additional-text-container">
          Endair Towers | Tripoli-Libya
          </div>
        );
      case 1:
        return (
          <div className="additional-text-container">
          Al Ahly Mall | Benghazi-Libya
          </div>
        );
      case 2:
        return (
          <div className="additional-text-container">
              Al Fadheel | Benghazi-Libya
          </div>
        );
      case 3:
        return (
          <div className="additional-text-container">
       Tripoli Seafront | Tripoli-Libya
          </div>
        );
      case 4:
        return (
          <div className="additional-text-container">
           
          Benghazi Modern Hospital | Benghazi - Libya
          </div>
        );
      case 5:
        return (
          <div className="additional-text-container">
         
         Ouzu Hotel | Benghazi - Libya
        
         
          </div>
        );
      case 6:
        return (
          <div className="additional-text-container">

Sousa Resort | Sousa-Libya
          </div>
        );
      default:
        return null;
    }
  };
  

  return (
    <div>
      
      
      <Carousel onSelect={handleSlideChange} >
      
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Endair_Towers.jpg"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/AlAhly_Mall.jpg"
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/AlFadheel_Urban_Center.jpg"
            alt="Fourth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Tripoli_Sea_Front_Towers.jpg"
            alt="Fifth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Benghazi_Modern_Hospital.jpg"
            alt="Fifth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Ozu_Hotel.jpg"
            alt="Fifth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Sousa_Resort.jpg"
            alt="Fifth slide"
          />
        </Carousel.Item>
       
       
        {/* Add more Carousel.Item components for additional images */}
      </Carousel>
   
     
      <div className="additional-image-container">
        <img
          
          src="/cops.png"
          alt="Additional image"
        />
     
      
        
      </div>
      <div className="mobile-image-container">
        <img
          className="mobile-image-container"
          src="/dotsmobile.png"
          alt="mobile-image"
        />
        
        
      </div>
    
   <div className='additionalText'>
      {renderAdditionalText()}
   </div>
   
<div className="copyright-notice">
  All rights are reserved for the Libyan Engineering Consulting Co, 2024
</div>

<div className="social-media-icons">
      
      <a href="" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a href="https://www.instagram.com/leo_engineering_consultancy?igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://www.facebook.com/share/AV77PGDkxVe3Zp3R/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
      </a>
  </div>
  
<div className="contact-info-container">
  
    <div className="contactsList">
        <p>
            <strong>Benghazi Office<br/></strong>
            8 Al Magron St - Dubai St.<br/>
            Benghazi - Libya<br/>
            PO BOX: 665<br/>
           
            Tel:+218 927702555<br/>
            E-mail:Info@leoarch.com<br/>

        </p>
        <p>
            <strong>Tripoli Office<br/></strong>
            15 Ayas Bin Aws<br/>
            Ben Ashur st, Al Saqa Mosque<br/>
            Tripoli - Libya<br/>
            Tel:+218 917702555<br/>
         
        
        </p>

        <p>
            <strong>Cairo Office<br/></strong>
            14 A Cleopatra Street<br/>
            1st Floor, Korba<br/>
            Heliopolis, 11341, Cairo - Egypt.<br/>
            Tel:+20 100 100 9654<br/>
       
        </p>
    </div>
</div>

<div></div>

    </div>
  
    
  );
}

export default Gallery;
