import React, { useState, useEffect } from 'react';
import './Contacts.css';
import Footer from './Footer';

const Contacts = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const carouselImages = [
    '/SecondaryCarousel_1.png',
    '/SecondaryCarousel_2.png',
    '/SecondaryCarousel_3.png',
    '/SecondaryCarousel_4.png',
    '/SecondaryCarousel_5.png',
    '/SecondaryCarousel_6.png',
    '/SecondaryCarousel_7.png',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % carouselImages.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [carouselImages]);

  return (
    <div className="contacts">
      {/* Carousel Section */}
      <div className="carousel-container">
        <img
          src={carouselImages[currentImage]}
          alt="Carousel"
          className="carousel-image"
        />
      </div>

      {/* Offices Section */}
      <div className="offices-section">
        <h1 className="section-title">Our Offices</h1>
        <div className="offices-container">
          <div className="offices-column">
            <p className="office-title">Headquarters</p>
            <p className="office-details">
              8 Al Magron St - Dubai St. Benghazi - Libya<br />
              PO BOX: 665<br />
              Tel: +218 917702555<br />
              Tel: +218 927702555
            </p>

            <p className="office-title">Tripoli Office</p>
            <p className="office-details">
              15 Ayas Bin Aws Ben Ashur St, Al Saqa Mosque<br />
              Tripoli - Libya<br />
              Tel: +218 61 478 5151<br />
              Tel: +218 91 3170088
            </p>
          </div>

          <div className="offices-column">
            <p className="office-title">Cairo Office</p>
            <p className="office-details">
              14 A Cleopatra Street 1st Floor,<br />
              Korba Heliopolis, 11341, Cairo - Egypt<br />
              Tel: +20 2 2291 45 65<br />
              Tel: +20 100 100 9654
            </p>

            <p className="office-title">Public Relations</p>
            <p className="office-details">For inquiries, contact our PR department.</p>

            <p className="office-title">Human Resources</p>
            <p className="office-details">Reach out for career opportunities and HR matters.</p>
          </div>
        </div>

        {/* Centered Photo */}
     
          <img
            src="/Basemap-image.png"
            alt="Contact Image"
            className="centered-photo"
          />
      
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
