import React, { useState, useEffect } from 'react';
import './Section3.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const newsHeadlines = [
  { image: '/thumbnail1_Journal_Figma.png', title: 'Libya Build 2024', subtitle: 'Our participation in Libya biggest engineering event of the year.' },
  { image: '/thumbnail2_Journal_Figma.png', title: '65 years Celebration', subtitle: 'Our celebration of over six decades of architectural work.' },
  { image: '/thumbnail3_Journal_Figma.png', title: 'Collaborations', subtitle: 'Our significant collaborations throughout the years.' },
];

const galleryImages = [
  { src: '/Section3_Figma_1.png', title: 'A Glimpse into the Past', body: 'Exploring our heritage, a selection of project photos.' },
  { src: '/Section3_Figma_2.png', title: 'New generations of Libyan architects', body: 'Shaping the Future of Design in Libya' },
  { src: '/Section3_Figma_3.png', title: 'Engineers Syndicate Conference', body: 'Benghazi Construction Exhibition' },
];

const Section3 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const handleNewsItemClick = (index) => {
    setCurrentIndex(index);
  };

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  const handleDownload = () => {
    window.open('https://drive.google.com/file/d/1uw-SnQdDkECQev_Rewr3ax-sJdDD9aj9/view?usp=sharing', '_blank');
  };

  const currentImage = galleryImages[currentIndex];

  return (
    <div className="section3" id="section3">
      {/* Invisible wrapper to maintain center alignment */}
      <div className="section3-news-updates">
              <h2>News & Updates</h2>
              <div className="section3-line"></div>
            </div>
      
        <div className="section3-content-container">
          <div className="section3-left-content">
            {/* Title and line at the top */}
         

            {/* Added Journal heading */}
            <div className="section3-journal-title">Journal</div>

            {/* News Headlines */}
            <div className="section3-news-headlines">
              {newsHeadlines.map((item, index) => (
                <div
                  className="section3-news-item"
                  key={index}
                  onClick={() => handleNewsItemClick(index)}
                >
                  <img src={item.image} alt={item.title} className="section3-news-image" />
                  <div className="section3-news-text">
                    <h3 className="section3-news-title">{item.title}</h3>
                    <p className="section3-news-subtitle">{item.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Button and Social Media Icons */}
            <div className="section3-explore-icons">
              <button className="section3-explore-button" onClick={handleDownload}>Explore More</button>
              <div className="section3-social-icons">
              <a  href="https://www.facebook.com/share/AV77PGDkxVe3Zp3R/?mibextid=LQQJ4d"><FontAwesomeIcon  icon={faFacebookF} /> </a>
              <a href= "https://www.instagram.com/leo_engineering_consultancy?igsh=MzRlODBiNWFlZA=="> <FontAwesomeIcon icon={faInstagram}  /> </a>
              <a href="https://linkedin.com"> <FontAwesomeIcon icon={faLinkedinIn} /></a>
              </div>
            </div>
          </div>

          {/* Gallery */}
          <div className="section3-gallery">
            <img src={currentImage.src} alt={`Gallery ${currentIndex + 1}`} className="section3-gallery-image" />
            <div className="section3-progress-indicators">
              {galleryImages.map((_, index) => (
                <div
                  key={index}
                  className={`section3-indicator ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => handleIndicatorClick(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

  );
};

export default Section3;
